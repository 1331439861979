import {ICountry} from 'projects/library/src/app/models/country.interface'
import {IProvince} from 'projects/library/src/app/models/province.interface'
import {Abilities} from '@/lib/app/models/abilities.type'
import {IUser} from '@/lib/app/models/user.interface'
import {IQuestionnaire} from '@/lib/app/models/questionnaire.interface'
import {IEnterprise} from '@/lib/app/models/enterprise.interface'
import {IEnterpriseDivision} from '@/lib/app/models/division.interface'

export interface IOrg {
  id: number
  enterprise_id: IEnterprise['id']
  enterprise_division_id: IEnterpriseDivision['id']
  name: string
  industry_type: number
  is_test: boolean
  country_id: ICountry['id']
  province_id: IProvince['id']
  country: ICountry
  province: IProvince
  city: string
  default_creator_id: null | IUser['id']
  stripe_id: null | string
  pm_type: null | string
  pm_last_four: null | string
  onboarded_at: null | string
  meta: {
    bill: 'business' | 'enduser'
    stripe_price_id?: string
    theme?: string
    questionnaire_uuid: string
    pre_plan_questionnaire_uuid: string
    pre_need_questionnaire_uuid: string
    can_mark_case_as_pre_paid?: boolean
    logo_url: string
    lead_gen_executor_assistant_questionnaire_id?: IQuestionnaire['id']
    planning_for_the_future_questionnaire_id?: IQuestionnaire['id']
    pre_need_notify: string[]
    google_place_id?: string
    primary_color?: string
    secondary_color?: string
    tertiary_color?: string
    enduser_support_url?: string
  }
  asd_meta: null | OrgAsdMeta
  __abilities: Abilities
}

export enum IndustryType {
  FUNERAL_HOME = 1,
  FINANCIAL_INSTITUTION = 2,
  OTHER = 3,
}

interface OrgAsdMeta {
  is_integration_enabled: boolean
  has_integration_ever_been_enabled: boolean
}

<div class="d-flex justify-content-between align-items-start mb-2">
  <h4>
    @if (mode === 'view') {
      Contact information
    } @else if (mode === 'edit') {
      Edit invitation
    }
  </h4>

  <div *ngIf="mode === 'view'" class="dropdown">
    <button class="btn btn-light py-0 px-2 bg-white border-0" data-bs-toggle="dropdown">
      <i class="fa-solid fa-ellipsis-v"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <li>
        <span [tooltip]="shouldDisableEdit ? 'The invitation cannot be edited because the invitation has already been accepted' : ''">
          <button class="dropdown-item" (click)="onEdit()" [disabled]="shouldDisableEdit">
            <i class="fa-solid fa-edit me-2"></i>Edit invitation
          </button>
        </span>
      </li>
      <li>
        <button class="dropdown-item" (click)="onBookMeeting()">
          <i class="fa-solid fa-calendar-day me-2"></i>
          Book a meeting with a Cadence advisor
          <i class="fa-solid fa-external-link-alt text-muted small ms-1"></i>
        </button>
      </li>
      <li *ngIf="case.owner.case_invitation?.status === InvitationStatuses.PENDING">
        <button class="dropdown-item" (click)="resendInitialCaseInvitation()">
          <i class="fa-light fa-envelope me-2"></i>
          Resend invitation&hellip;
        </button>
      </li>
    </ul>
  </div>
</div>

<ng-container *ngIf="mode === 'view'">
  <div class="row mb-3">
    <div class="col-sm-3 text-muted">Name</div>
    <div class="col-sm-9">
      <span class="me-2 text-truncate is-pii" [title]="case.owner.name">
        @if (case.owner.name) { {{ case.owner.name }} } @else { <span class="text-muted">(empty) </span>}
      </span>
      <ng-container *ngIf="caseOwnerStatus">
        <abbr
          class="text-muted"
          [tooltip]="caseOwnerStatus.tooltip"
          [tooltipHtml]="true"
          (click)="caseOwnerStatus.click && caseOwnerStatus.click()"
          tooltipPlacement="right"
          >{{ caseOwnerStatus.label }}
        </abbr>
      </ng-container>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-3 text-muted">Email</div>
    <div class="col-sm-9 text-truncate is-pii" [title]="case.owner.email">
      @if (case.owner.email) { {{ case.owner.email }} } @else { <span class="text-muted">(empty) </span>}
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-3 text-muted">Phone</div>
    <div class="col-sm-9 text-truncate is-pii" [title]="case.owner.phone | phone">
      @if (case.owner.phone) { {{ case.owner.phone | phone }} } @else { <span class="text-muted">(empty) </span>}
    </div>
  </div>

  @if (isAfterCare && case.owner.case_invitation?.status === InvitationStatuses.ACCEPTED) {
    <div class="row mb-3">
      <div class="col-sm-3 text-muted">Grief Care</div>
      <div class="col-sm-9 text-truncate is-pii">
        {{ case.owner.is_subscribed_to_grief_emails ? "Subscribed to Grief Care newsletter" : "Not subscribed to Grief Care newsletter" }}
      </div>
    </div>
  }

  @if (case.owner.case_invitation?.status === InvitationStatuses.DRAFT) {
    <div class="alert alert-info">
      <h5>Invite not sent</h5>
      <p>The contact has not been invited to use Cadence yet.</p>
      <button class="btn btn-primary" (click)="onOpenSendInvitation()">Send invitation&hellip;</button>
    </div>
  }

  <div class="mb-4">
    <span class="me-2">Demo mode</span>
    <span
      [tooltip]="
        'Demo cases are ideal for internal use such as training or demos. They are free of charge and excluded from any usage reports.'
      ">
      <i class="fa-regular fa-circle-info"></i>
    </span>
    <div class="form-check form-switch ps-0">
      <div class="row">
        <div class="ms-0 col-9">
          <label class="form-check-label" for="isDemo">Turn ON to mark this case as a demo case</label>
        </div>
        <div class="col-3 d-flex justify-content-end">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="isDemo"
            [(ngModel)]="case.is_test"
            (change)="toggleDemoStatus()" />
        </div>
      </div>
    </div>
  </div>

  <org-case-access-switch [case]="case" />
</ng-container>

@if (mode === 'edit') {
  <form (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <org-case-member-form-group #memberFormGroupComponent></org-case-member-form-group>
    </div>

    <button type="submit" class="btn btn-primary me-3" [ngClass]="{disabled: !memberFormGroupComponent.form.valid}" [disabled]="!memberFormGroupComponent.form.valid">
      Save
    </button>
    <button type="button" class="btn btn-link" (click)="onCancelEdit()">Cancel</button>
  </form>
}

<ng-template #sendInviteModal>
  <div class="modal fade" [class.form-elements-disabled]="!!pendingRequests.numPendingRequests" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Send invitation</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="text-muted">
            The contact has not been invited to use Cadence yet.
          </p>
          <p class="text-muted">
            Enter the information to send an invitation with instructions on how they can get started with Cadence.
          </p>
          <form (ngSubmit)="onSendInvitation()">
            <div class="mb-4">
              <org-case-member-form-group #memberFormGroupComponent></org-case-member-form-group>
            </div>

            <button type="submit" class="btn btn-primary me-3" [ngClass]="{disabled: !memberFormGroupComponent.form.valid}" [disabled]="!memberFormGroupComponent.form.valid">
              Send
            </button>
            <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
